import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Helmet} from 'react-helmet';

const {Brand, Collapse, Toggle} = Navbar;
const {Item: NavItem, Link: NavLink} = Nav;
import '../../sass/style.scss';

export default function Home() {
    const year = new Date().getFullYear();

    return (
        <Container className="p-0" fluid>
            <Helmet>
                <meta charSet="utf-8" />
                <title>B&amp;G Financial</title>
            </Helmet>
            <Row className="mx-0 p-0">
                <Col className="topBar" />
            </Row>
            <Navbar collapseOnSelect expand="md">
                <Brand href="/" className="mx-auto">
                    <Image src="/images/bandg-logo.jpg" />
                </Brand>
                <Toggle aria-controls="responsive-navbar-nav" />
                <Collapse className="justify-content-end" id="responsive-navbar-nav">
                    <Nav>
                        <NavItem className="pr-3">
                            <NavLink href="#who-we-are">WHO WE ARE</NavLink>
                        </NavItem>
                        <NavItem className="pr-3">
                            <NavLink href="#goals">GOALS</NavLink>
                        </NavItem>
                        <NavItem className="pr-3">
                            <NavLink href="#history">HISTORY</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
            <Row className="mx-0">
                <Col className="p-0 m-0">
                    <Image fluid src="/images/driving-picture.jpg" />
                </Col>
            </Row>
            <Container>
                <Row className="mx-0 quote p-5">
                    <Col className="text-center">
                        <h1>
                            “B&amp;G Financial only partners with the best Credit Unions
                            so that we can help our customers increase the value of
                            their money”
                        </h1>
                        <h2 className="text-lg-right">
                            Jennifer Box
                        </h2>
                    </Col>
                </Row>
                <Row className="mx-0 mt-5" name="who-we-are">
                    <Col md={10} className="offset-md-1">
                        <h1>Who We Are</h1>
                        <p>
                            B&amp;G Financial, LLC, established in 2016, is a financial
                            services company located in Saint Louis, Missouri focusing on
                            automotive refinance.
                        </p>
                        <p>
                            We believe automotive refinance is a hidden opportunity for
                            both members and Credit Unions. Our experience is that many
                            people don’t know they are paying too much interest and
                            can simply “exchange their car payment” for a lower one. Our
                            process is fast, high tech, and simple. We are the only
                            company on the planet doing this.
                        </p>
                        <p>
                            B&amp;G Financial partners with only best in class credit
                            unions whose aspirations match our own. We want to help
                            people make smart decisions with their finances. We get that
                            started one automotive refinance loan at a time.
                        </p>
                    </Col>
                </Row>
                <Row className="mx-0" name="goals">
                    <Col md={10} className="offset-md-1">
                        <h1>Our Goals</h1>
                        <p>
                            Our higher cause is simple – we want to help members have the
                            lowest total cost of ownership. We find that by focusing on
                            the variables and helping them control them – depreciation,
                            interest, repairs, taxes – we can help members use that extra
                            money to save for retirement.
                        </p>
                    </Col>
                </Row>
                <Row className="mx-0" name="history">
                    <Col md={10} className="offset-md-1">
                        <h1>Our History</h1>
                        <p>
                            B&amp;G Financial was started in 2016 by Jennifer Box. The company is  
                            based out of St. Louis, Missouri. What began as a simple finance company 
                            has grown into a finance company with a strong marketing arm. It’s 
                            a unique mix that makes up our secret sauce.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Row className="footer p-5 mx-0 mt-5">
                <Col md={4} className="py-4 offset-md-1">
                    <h1>B&amp;G Financial</h1>
                </Col>
                <Col md={3} className="offset-md-3">
                    <h2>CONTACT</h2>
                    <p>
                        (314) 530-5270<br />
                        <a href="mailto:info@bandgfinancial.com">info@bandgfinancial.com</a><br />
                        12801 Flushing Meadows Drive,<br />
                        Des Peres, MO 63131<br />
                        Suite 200
                    </p>
                </Col>
            </Row>
            <Row className="copyright pt-3 pb-2 mx-0">
                <Col className="text-center text-md-right">
                    <p>© B&amp;G Financial 2016-{year} All Rights Reserved</p>
                </Col>
            </Row>
        </Container>
    );
}
